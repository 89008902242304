import '@alenaksu/json-viewer';
import {consume} from '@lit/context';
import {LitElement, html, nothing} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {ApiClient} from '../../api-client/index';
import {apiClientContext} from '../../context/api/index';
import {getVtModule} from '../../domain/rulesets';
// @ts-ignore
import {JsonViewer} from '@alenaksu/json-viewer/dist/JsonViewer';
import {Task} from '@lit/task';
import {choose} from 'lit/directives/choose.js';
import type {Ref} from 'lit/directives/ref.js';
import {createRef, ref} from 'lit/directives/ref.js';
import {VtApiError} from '../../api-client/error';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {assertVtModulePropertyValueExpression} from '../../context/editor/language/yara/rule-builder';
import {arrowRotateRightIcon, magnifyingGlassIcon} from '../../icons';
import {isPending} from '../../utils';
import {AddSnippetToRulesetRequested} from '../../view/events';

@customElement('sidebar-structure')
export class SidebarStructure extends LitElement {
  @property({type: String})
  private kind?: RulesetTargetKind;

  @consume({context: apiClientContext})
  @property({attribute: false})
  public apiClient!: ApiClient;

  private vtModuleTask?: Task<[], {vt: Record<string, unknown>}>;

  @query('#jsonViewer')
  private jsonViewer: JsonViewer;

  @state()
  private filterValue = '';

  private formRef: Ref<HTMLFormElement> = createRef();

  public createRenderRoot() {
    return this;
  }

  private formSubmitted(e: SubmitEvent) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const kind = formData.get('kind') as RulesetTargetKind;
    const entityId = formData.get('entityId') as string;
    this.vtModuleTask = new Task(this, () =>
      getVtModule(this.apiClient, kind, entityId)
    );
    this.vtModuleTask.run().then(() => {
      this.jsonViewer.expandAll();
    });
  }

  private getPropertyPathAndValue(e: PointerEvent) {
    const domPath = e.composedPath(); // Get the composed path of the event
    let value;
    let path;

    // Iterate over the composed path from the target element to the top-level element
    for (const pathElement of domPath) {
      const element = pathElement as HTMLSpanElement;
      // Get the leaf node of the tree. Property value
      if (
        'attributes' in element &&
        element.attributes &&
        'part' in element.attributes &&
        (element.attributes.part as {value: string}).value.indexOf(
          'primitive'
        ) !== -1
      ) {
        value = element.innerText;
      }

      // Get the property name
      if (
        element.attributes &&
        'part' in element.attributes &&
        (element.attributes.part as {value: string}).value.indexOf(
          'property'
        ) !== -1 &&
        'path' in element.dataset
      ) {
        path = element.dataset.path;
        break;
      }
    }
    return {value, path};
  }

  private generateRuleName(propertyPath: string, propertyValue: string) {
    return `${propertyPath
      .split('.')
      .filter((part) => !/^\d+$/.test(part))
      .join('_')}__${propertyValue.replace(/"/g, '').replace(/\W/g, '_')}`;
  }

  private createYaraRule(propertyPath: string, propertyValue: string) {
    return `rule \${1:${this.generateRuleName(propertyPath, propertyValue)}} {
  meta:
    target_entity = "${this.kind}"
  condition:
${assertVtModulePropertyValueExpression(propertyPath, propertyValue, 2)}
}$0`;
  }

  private handleClick(e: PointerEvent) {
    const {value, path} = this.getPropertyPathAndValue(e);
    if (!value && path === 'vt') {
      // This code executes AFTER the component handled the expansion/collapse...
      const numberOfLevelsExpanded = (
        this.jsonViewer as HTMLElement
      ).shadowRoot!.querySelectorAll<HTMLUListElement>('ul').length;
      if (numberOfLevelsExpanded > 1) {
        //... so if there are more than 1 levels expanded, the data was collapsed before the click.
        this.jsonViewer.expandAll();
      }
    } else if (value && path) {
      const yaraRule = this.createYaraRule(path, value);
      this.dispatchEvent(new AddSnippetToRulesetRequested(yaraRule, ['vt']));
    }
  }

  private filterHandler() {
    if (!this.filterValue) {
      this.jsonViewer.resetFilter();
      this.jsonViewer.expandAll();
      return;
    }

    const searchIterator = this.jsonViewer.search(this.filterValue);
    const result = searchIterator.next();

    // Search produced some results, remove filter.
    if (result.value) {
      this.jsonViewer.resetFilter();
    } else {
      const newFilter = new RegExp(`.*${this.filterValue}.*`, 'i');
      this.jsonViewer.filter(newFilter);
      this.jsonViewer.expandAll();
    }
  }

  private filterContent(event: Event) {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    this.filterValue = formData.get('filterText') as string;
    this.filterHandler();
  }

  private onEntityInputPaste() {
    setTimeout(() => this.formRef.value?.requestSubmit());
  }

  private renderBody() {
    return html`<form
        id="structure-form"
        class="d-flex"
        @submit=${this.formSubmitted}
        ${ref(this.formRef)}
      >
        <input type="hidden" name="kind" value=${this.kind || 'file'} />
        <div class="input-group">
          <input
            name="entityId"
            class="form-control flex-grow-1"
            type="text"
            placeholder="Paste ${this.renderAnIoC(this.kind)}"
            @paste="${this.onEntityInputPaste}"
          />
          <button
            type="submit"
            class="btn btn-outline-secondary hstack fs-5 px-2 border"
          >
            ${arrowRotateRightIcon}
          </button>
        </div>
      </form>
      ${this.vtModuleTask?.render({
        error: (e) => html`<div class="alert alert-dismissible alert-danger">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
          ></button>
          <div>
            ${e instanceof VtApiError && e.status === 404
              ? `Provided IOC not found at VirusTotal`
              : VtApiError.getMessage(
                  e,
                  'There was an error retrieving the module data'
                )}
          </div>
        </div>`,
        complete: (data) => html` <div class="d-flex">
            <form class="input-group" @submit="${this.filterContent}">
              <input
                name="filterText"
                class="form-control flex-grow-1"
                type="text"
                placeholder="Filter"
                .value="${this.filterValue}"
              />
              <button
                type="submit"
                class="btn btn-outline-secondary hstack fs-5 px-2 border"
              >
                ${magnifyingGlassIcon}
              </button>
            </form>
          </div>
          <div class="alert alert-dismissible alert-info">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
            ></button>

            Navigate through the attributes in the VT module and click on the
            values to populate a Yara rule condition
          </div>
          <json-viewer
            id="jsonViewer"
            @click="${this.handleClick}"
            .data="${data}"
          >
          </json-viewer>`,
      })}`;
  }

  private renderAnIoC(kind: RulesetTargetKind = 'file') {
    return choose(kind, [
      ['file', () => 'a hash'],
      ['url', () => 'a URL'],
      ['domain', () => 'a domain'],
      ['ip_address', () => 'an IP address'],
    ]);
  }

  public render() {
    return html`
      <style>
        json-viewer {
          /* Background, font and indentation */
          --background-color: var(--bs-secondary-bg);
          --color: var(--bs-secondary-color);
          --font-family: Menlo, Monaco, 'Courier New', monospace;
          --font-size: 12px;
          --indent-size: 1.5em;
          --indentguide-size: 1px;
          --indentguide-style: solid;
          --indentguide-color: var(--bs-border-color);
          --indentguide-color-active: var(--bs-tertiary-color);
          --indentguide: var(--indentguide-size) var(--indentguide-style)
            var(--indentguide-color);
          --indentguide-active: var(--indentguide-size) var(--indentguide-style)
            var(--indentguide-color-active);

          /* Types colors */
          --string-color: var(--bs-danger);
          --number-color: var(--bs-success);
          --boolean-color: var(--bs-info);
          --null-color: var(--bs-body-color);
          --property-color: var(--bs-primary);

          /* Collapsed node preview */
          --preview-color: var(--bs-disabled-color);

          /* Search highlight color */
          --highlight-color: var(--bs-tertiary-bg);
        }

        json-viewer::part(primitive) {
          white-space: pre;
        }
      </style>
      <div class="vstack gap-3 p-3">
        <div class="m-0 p-0 d-flex justify-content-between">
          <h6 class="m-0 p-0">Structure</h6>

          ${this.vtModuleTask && isPending(this.vtModuleTask)
            ? html`<div class="text-center">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>`
            : nothing}
        </div>

        <p class="m-0">
          Paste ${this.renderAnIoC(this.kind)} here to create rules based on its
          attributes.
        </p>

        ${this.renderBody()}
      </div>
    `;
  }
}
